import React, { Component } from "react";
import { Container, Row, Card, Alert } from 'react-bootstrap';
import { MDBIcon, MDBBtn } from 'mdb-react-ui-kit';
import UguisTextEditor from "./UguisTextEditor"
import { Statement } from './SampleData';
import Dialog from 'react-bootstrap-dialog'
import eventBus from "../EventBus";
import {WordCount} from '../utils'
import * as device from '../device';

export default class EssayView extends React.Component {
  constructor(props) {
    super(props);
    this.viewRef = React.createRef()
  }

  getInputValue() {
    return this.viewRef.current?.getInputValue()
  }

  essaySaved() {
    return this.viewRef.current?.essaySaved()
  }

  isEssaySaved() {
    return this.viewRef.current?.isEssaySaved()
  }

  render() {
    if (this.props.assignment == null) {
      return null;
    }
    if (this.props.assignment.question.type == 'opinion') {
      return (
        <OpinionEssayView
          ref={this.viewRef}
          assignment={this.props.assignment}
          keyboardOff={this.props.keyboardOff}
          onEditorFocused={this.props.onEditorFocused}/>
      )
    }
    else if (this.props.assignment.question.type == 'email') {
      return (
        <EMailEssayView
          ref={this.viewRef}
          assignment={this.props.assignment}
          keyboardOff={this.props.keyboardOff}
          onEditorFocused={this.props.onEditorFocused}/>
        )
    }
    else if (this.props.assignment.question.type =='summary') {
      return (
        <SummaryEssayView
          ref={this.viewRef}
          assignment={this.props.assignment}
          keyboardOff={this.props.keyboardOff}
          onEditorFocused={this.props.onEditorFocused}/>
      )
    }
  }
}

class AbstractEssayView extends React.Component {
  dialog;
  constructor(props) {
    super(props);
    this.editorRef = React.createRef()

    let grade = props.assignment['question']['grade'];
    let type = props.assignment['question']['type'];
    this.suggestedLength = Statement[grade][type]['suggested_length']

    this.state = {
      essayChanged: false,
      assignment: props.assignment,
      seed: Math.random(),
      wordCount: WordCount(props.assignment.essay),
    }
  }

  updateWordCountFromInputValue() {
    let wordCount = WordCount(this.getInputValue());
    this.updateWordCount(wordCount);
  }

  updateWordCount(wordCount) {
    this.setState(prevState => ({
      ...prevState,
      wordCount: wordCount,
    }))
  }

  updateEssayChanged() {
    this.setState(prevState => ({
      ...prevState,
      essayChanged: true,
    }))
  }

  handleEssayChange = (value) => {
    this.updateWordCountFromInputValue();
    this.updateEssayChanged();
  }

  handleClearEssay = async(e) => {
    this.dialog.show({
      title: '書かれている解答をクリアしますか？',
      body: '解答をクリアすると元に戻せません。',
      actions: [
        Dialog.Action(
          'キャンセル',
          () => this.dialog.hide(),
          'btn-secondary'
        ),
        Dialog.Action(
          'クリアする',
          async () => {
            this.setState(prevState => ({
              ...prevState,
              assignment: {
                ...prevState.assignment,
                essay: null,
                feedback: null,
              },
              seed: Math.random(),
              wordCount: 0,
            }))
          },
          'btn-danger'
        )
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      }
    })
  }

  showHint() {
    eventBus.dispatch("chat", {
      'type': 'general',
    })
  }

  essaySaved() {
    this.setState(prevState => ({
      ...prevState,
      essayChanged: false
    }))
  }

  isEssaySaved() {
    return !this.state.essayChanged
  }

  getInputValue() {
    // console.log('get input value')
    return this.editorRef.current?.getInputValue()
  }

  getEssayNotSavedWarning() {
    if (!this.state.essayChanged) {
      return null
    }
    return (
        <Alert key='warning' variant='warning' className='py-1 px-3 mt-3 mb-0'>
          <small>
            書きかけの文章は保存されていません。AI採点して文章を保存しましょう。
          </small>
        </Alert>
      )
  }

  getClearButton() {
    return (
      <div className="d-flex align-items-center justify-content-between mt-3">
        <MDBBtn size="sm" fluid className={`gradient-bubble outline`} rounded onClick={this.handleClearEssay}>解答をクリア</MDBBtn>
        <div className="small text-muted"><span
        className={`${this.state.wordCount < this.suggestedLength.min ? 'text-danger': (this.state.wordCount > this.suggestedLength.max ? 'text-warning':'text-success')}`}>{this.state.wordCount}語</span><span>／{this.suggestedLength.min}語～{this.suggestedLength.max}語</span></div>
      </div>
    )
  }

  getChatButton() {
    return (
      <div className="d-flex align-items-center">
        <span className="small text-muted me-1">UGUIS先生</span>
        <MDBBtn floating size="sm" className="green-button" onClick={this.showHint}>
          <MDBIcon far icon="comment" className="me-1"/>
        </MDBBtn>
      </div>
    )
  }

  getPlaceholder() {
    return device.isIOSApp() === false ? '解答を英文で入力、または、手書きをカメラで読み込みましょう' : '解答を英文で入力しましょう'
  }

}


class OpinionEssayView extends AbstractEssayView {
  render() {
    return (
      <Card>
        <Card.Header className="px-md-4 px-1 py-md-2 py-1">
          <div className="d-flex align-items-center justify-content-between">
            <div className='card-headder-margin'>
              <MDBIcon fas icon="pen me-2 align-middle" />
              <span className="align-middle fw-bold">あなたの解答</span>
            </div>
            {this.getChatButton()}
          </div>
        </Card.Header>
        <Card.Body className='p-md-4 p-1 pb-3'>
          <div className="px-3 py-2 border border-dark border-2 rounded">
            <UguisTextEditor
              key={this.state.seed}
              ref={this.editorRef}
              feedback={this.state.assignment.feedback}
              essay={this.state.assignment.essay}
              question_data={this.state.assignment.question}
              placeholder={this.getPlaceholder()}
              onEssayChange={this.handleEssayChange}
              keyboardOff={this.props.keyboardOff}
              onFocused={this.props.onEditorFocused}
            />
          </div>
          {this.getClearButton()}
          {this.getEssayNotSavedWarning()}
        </Card.Body>
        <Dialog ref={(el) => { this.dialog = el }} />
      </Card>
    )
  }
}

class EMailEssayView extends AbstractEssayView {
  render() {
    let statement_data = Statement[this.state.assignment.question.grade]['email']

    return (
      <Card>
        <Card.Header className="fw-bold px-md-4 px-1 py-md-2 py-1">
          <div className="d-flex align-items-center justify-content-between">
            <div className='card-headder-margin'>
              <MDBIcon far icon="envelope me-2 align-middle"/>
              <span className="align-middle">Re: {this.state.assignment.title}</span>
            </div>
            {this.getChatButton()}
          </div>
        </Card.Header>
        <Card.Body className='p-md-4 p-1'>
          <Card.Title>
            <img src="profile.png" height="30" alt="avatar" loading="lazy" className='rounded-circle me-2'/>
            <span className="h6">me</span>
          </Card.Title>
          <div className="email email-reply">
            <div>{this.state.assignment.question.greeting}</div>
            <div>{statement_data['content_start']}</div>
          </div>
          <div className="px-3 py-1 border border-dark border-2 rounded">
            <UguisTextEditor
              key={this.state.seed}
              ref={this.editorRef}
              feedback={this.state.assignment.feedback}
              essay={this.state.assignment.essay}
              question_data={this.state.assignment.question}
              placeholder={this.getPlaceholder()}
              onEssayChange={this.handleEssayChange}
              keyboardOff={this.props.keyboardOff}
              onFocused={this.props.onEditorFocused}
            />
          </div>
          <div className="email email-reply">{this.state.assignment.question.closing}</div>
          {this.getClearButton()}
          {this.getEssayNotSavedWarning()}
        </Card.Body>
        <Dialog ref={(el) => { this.dialog = el }} />
      </Card>
    )
  }
}

class SummaryEssayView extends AbstractEssayView {
  render() {
    return (
      <Card>
        <Card.Header className="fw-bold px-md-4 px-1 py-md-2 py-1">
          <div className="d-flex align-items-center justify-content-between">
            <div className='card-headder-margin'>
              <MDBIcon fas icon="pen me-2 align-middle" />
              <span className="align-middle fw-bold">あなたの解答</span>
            </div>
            {this.getChatButton()}
          </div>
        </Card.Header>
        <Card.Body className='px-md-4 py-3 py-md-4 px-0'>
          <div className="p-1 p-md-3 border border-dark border-2 rounded">
            <UguisTextEditor
              key={this.state.seed}
              ref={this.editorRef}
              feedback={this.state.assignment.feedback}
              essay={this.state.assignment.essay}
              question_data={this.state.assignment.question}
              placeholder={this.getPlaceholder()}
              onEssayChange={this.handleEssayChange}
              keyboardOff={this.props.keyboardOff}
              onFocused={this.props.onEditorFocused}
            />
          </div>
          {this.getClearButton()}
          {this.getEssayNotSavedWarning()}
        </Card.Body>
        <Dialog ref={(el) => { this.dialog = el }} />
      </Card>
    )
  }
}
