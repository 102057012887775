import './App.css';

import {
  MDBBtn,
  MDBSpinner,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBRow,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
} from 'mdb-react-ui-kit';
import React from "react";
import { AuthContext } from "../Auth/AuthContext"
import { Container, Stack, Row, Col, OverlayTrigger, Tooltip, Alert } from 'react-bootstrap';
import { SampleAssignments } from './SampleData';
import { AssignmentCard, AssignmentSupplementCard } from './Assignment';
import { VerticalFeedback, HorizontalFeedback } from './Feedback'
import { Assignment, Question } from "./model/Assignment";
import EikenLogo from "../EikenLogo"
import * as api from '../api'
import TopicData from "../TopicData"
import EssayView from "./EssayView"
import Dialog from 'react-bootstrap-dialog'
import { withRouter } from './withRouter';
import { isMobile } from './WindowDimensions';
import {Helmet} from "react-helmet";
import QuestionTypeName from './QuestionTypeName';
import * as gtm from '../services/gtm'
import dayjs from 'dayjs';
import {WindowDimensionComponent} from './WindowDimensions';
import SamplingWithoutReplacement from '../SamplingWithoutReplacement';
import { JsonLocalStorage } from '../Storage';
import ChatDialog from './ChatDialog';
import eventBus from "../EventBus";

Array.prototype.random = function () {
  return this[Math.floor((Math.random()*this.length))];
}

class AssignmentContent extends WindowDimensionComponent {
  static contextType = AuthContext;
  dialog;
  windowScrollY = 0;
  previousWindowScrollY = 0;

  constructor(props) {
    super(props);

    this.essayRef = React.createRef()
    this.chatDialogRef = React.createRef()

    if (props.grade && props.type && ~props.isSample) {
      this.topicSampler = new SamplingWithoutReplacement(
        new JsonLocalStorage(`Uguis_remainingIndexes_${props.grade}_${props.type}`),
        TopicData[props.grade][props.type].flatMap(t => t.subTopics)
      );
    }

    this.state = {
      assignment: null,
      assignment_id: null,
      loadingMessage: null,
      loading: true,
      isSample: true,
      feedbackSeed: 1, // seed to refresh feedback view entirely
      essaySeed: 2, // seed to refresh feedback view in email assignment
      scoring: false,
      mode: 'keyboard',
      activeTab: "question",
      spacerHeight: 0,
      chatDialogShow: false
    }
  }

  hideChat = () => {
    this.setState(prevState => ({
      ...prevState,
      chatDialogShow : false
    }));
  }
  showChat = (feedback) => {
    if (this.context.user == null) {
      this.dialog.show({
        title: 'UGUIS先生にチャットで質問',
        body: 'AIのUGUIS先生にチャットでアドバイスがもらえます。チャットを始めるには、無料のユーザ登録をしてログインしてください。',
        actions: [
          Dialog.Action(
            'キャンセル',
            () => this.dialog.hide(),
            'btn-secondary'
          ),
          Dialog.Action(
            'ログイン',
            async () => {
              eventBus.dispatch("login");
            },
            'btn-secondary'
          ),
          Dialog.DefaultAction(
            'ユーザ登録',
            async () => {
              eventBus.dispatch("signup");
            },
            'btn-success'
          )
        ],
        bsSize: 'small',
        onHide: (dialog) => {
          dialog.hide()
        }
      })
      return;
    }

    this.setState(prevState => ({
      ...prevState,
      chatDialogShow : true
    }));
    const essay = this.essayRef.current?.getInputValue()
    this.chatDialogRef.current?.startChat(this.state.assignment, feedback, essay);
  }

  showHint() {
    eventBus.dispatch("chat", {
      'type': 'general',
    })
  }

  getTypeText(type) {
    return QuestionTypeName(type)
  }

  componentDidMount() {
    super.componentDidMount();
    const assignment_id = this.props.assignment_id
    const grade = this.props.grade
    const type = this.props.type
    const isSample = this.props.isSample
    this.load(assignment_id, grade, type, isSample)
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });

    eventBus.on("chat", (feedback) =>
      {
        this.showChat(feedback)
      }
    );
  }

  componentWillUnmount() {
    eventBus.remove("chat");
  }

  async load(assignment_id, grade, type, isSample) {
    this.setState(prevState => ({
      ...prevState,
      assignment_id: assignment_id,
      assignment: null,
      loading: true,
      mode: 'keyboard',
      activeTab: "question",
      spacerHeight: 0,
    }))
    let topic = this.props.topic
    // console.log('componentDidMount', 'assignment_id', assignment_id, 'grade', grade, 'type', type)
    if (assignment_id) {
      this.setState(prevState => ({
        ...prevState,
        loadingMessage: '課題をロードしています...',
        isSample: false,
      }));
      const assignment = await api.getUserAssignment(assignment_id)
      this.setState(prevState => ({
        ...prevState,
        assignment: assignment.assignment,
        mode: 'feedback',
        loading: false
      }))
    }
    else if (isSample) {
      const assignment = Assignment.fromJSON(SampleAssignments[grade][type])
      this.sampleEssay = assignment.essay
      this.sampleFeedback = assignment.feedback
      assignment.feedback = null
      assignment.essay = null
      // console.log('componentDidMount assignment', assignment)
      this.setState(prevState => ({
        ...prevState,
        assignment: assignment,
        isSample: true,
        mode: 'keyboard',
        loading: false,
      }))
    }
    else if (grade && type) {
      this.setState(prevState => ({
        ...prevState,
        loadingMessage: '問題を生成しています...',
      }));

      let subtopic = null;
      if (!topic) {
        subtopic = this.topicSampler.sample();
      }
      else {
        // This process is left in case the topic is provided
        const topics = TopicData[grade][type]
        const target_topic = topics.find(candidate => candidate.id === topic);
        subtopic = target_topic.subTopics.random();
      }

      const startTime = dayjs();
      const generated_question = api.generateQuestion(grade, type, subtopic);
      generated_question.then(qinfo => {
        const endTime = dayjs();
        const timeTaken = endTime.diff(startTime);

        qinfo['grade'] = grade;
        qinfo['type'] = type;
        const question = Question.fromJSON(qinfo);
        const assignment = new Assignment(
          qinfo.question_text,
          question);
        this.setState(prevState => ({
          ...prevState,
          assignment: assignment,
          loading: false,
          isSample: false,
          mode: 'keyboard',
        }));

        gtm.sendGenerationQuestionEvent(grade, type, subtopic, timeTaken);
      });
    }
  }

  handleShowSample = async(e) => {
    await this.setStateAsync(prevState => ({
      ...prevState,
      assignment: {
        ...prevState.assignment,
        essay:this.sampleEssay,
        feedback:this.sampleFeedback,
      }
    }))
    this.forceUpdateFeedback()
    this.setState(prevState => ({
      ...prevState,
    }))

  }

  componentWillUpdate() {
    this.windowScrollY = window.scrollY;
  }

  componentDidUpdate(prevProps, prevStates) {
    if (prevProps.assignment_id !== this.props.assignment_id) {
      if (this.props.assignment_id) {
        this.load(this.props.assignment_id)
      }
    }

    // restore scroll only in mobile
    if (this.isMobile()) {
      if (prevStates.activeTab != this.state.activeTab) {
        // restore previous y
        const toScroll = this.previousWindowScrollY;
        // remember current y
        this.previousWindowScrollY = this.windowScrollY;
        // scroll to previous y
        window.scroll({
          top: toScroll,
          behavior: "instant",
        })
      }
    }
  }

  // change space height so content is not covered by essayview
  handleHorizontalFeedbackSizeUpdated = (contentRect) => {
      const targetHeight = contentRect.height;
      if (this.state.spacerHeight != targetHeight) {
        this.setState(prevState => ({...prevState,
          spacerHeight: targetHeight,
        }));
      }
  }

  async forceUpdateFeedback() {
    await this.setStateAsync(prev => ({...prev,
      feedbackSeed: Math.random(),
      essaySeed: Math.random()
    }))
  }

  showSaveDialog(action) {
    if (!this.essayRef.current?.isEssaySaved()) {
      this.dialog.show({
        title: '解答を保存して移動しますか？',
        actions: [
          Dialog.Action(
            'キャンセル',
            () => this.dialog.hide(),
            'btn-secondary'
          ),
          Dialog.Action(
            '保存せずに移動',
            async () => {
              action();
            },
            'btn-danger'
          ),
          Dialog.DefaultAction(
            '保存してから移動',
            async () => {
              await this.score()
              action();
            },
            'btn-success'
          )
        ],
        bsSize: 'small',
        onHide: (dialog) => {
          dialog.hide()
        }
      })
    }
    else {
      action()
    }
  }

  newQuestion() {
    const grade = this.state.assignment.question.grade
    const type = this.state.assignment.question.type
    this.props.navigate(`/english?grade=${grade}&type=${type}`);
    this.load(null, grade, type, false)
  }

  handleNewAssignment = async (e) => {
    if (this.state.isSample && this.state.assignment.feedback != null) {
      this.props.navigate('/signUp');
      return;
    }

    let actions = [
      Dialog.Action(
        'キャンセル',
        () => this.dialog.hide(),
        'btn-secondary'
      )
    ]
    let body;
    if (!this.essayRef.current?.isEssaySaved()) {
      body = '今の解答を保存してから別の問題をAI自動生成します。'
      actions.push(Dialog.Action(
        '保存せずに問題を生成',
        async () => {
          this.newQuestion()
        },
        'btn-danger'
      ),
      Dialog.DefaultAction(
        '保存して問題を生成',
        async () => {
          await this.score()
          this.newQuestion()
        },
        'btn-success'
      ))
    }
    else {
      body = '別の問題をAI自動生成します。'
      actions.push(Dialog.DefaultAction(
        '問題を生成',
        async () => {
          this.newQuestion()
        },
        'btn-success'
      ))
    }

    this.dialog.show({
      title: '別の問題で学習しますか？',
      body: body,
      actions: actions,
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      }
    })
  }

  handleScore = async (e) => {
    await this.score()
  }

  handleHome = (e) => {
    this.showSaveDialog(() => {
      this.props.navigate('/');
    })
  }

  async setStateAsync(func) {
    return new Promise((resolve, reject) => {
      this.setState(func, () => {
        resolve()
        }
      )
    })
  }

  async score() {
    const essay = this.essayRef.current?.getInputValue()
    // console.log('essay', essay)

    // clear feedback
    let assignment = this.state.assignment
    assignment = {...assignment,
      essay: essay,
      feedback:null,
    }
    // show spinner
    this.setState(prevState => ({
      ...prevState,
      scoring: true,
    }));
    // score
    const startTime = dayjs();
    const feedback = await api.scoreAssignment(assignment)
    const endTime = dayjs();
    const timeTaken = endTime.diff(startTime);
    assignment = {...assignment,
      feedback:feedback,
    }

    let coherence_score
    switch (assignment.question.type) {
      case 'opinion':
        coherence_score = feedback.coherence.sys_coherence
        break
      case 'email':
        coherence_score = feedback.content.sys_content
        break
      case 'summary':
        coherence_score = feedback.coherence.sys_coherence
        break
      default:
        throw new Error(`unknown question type: ${assignment.question.type}`)
    }

    assignment.score = feedback.scores.total_score

    gtm.sendScoreEvent(assignment.question.grade, assignment.question.type, assignment.score, timeTaken)

    // if logged in, save feedback
    if (this.context.user != null) {
      // console.log('saving:start', assignment)
      if (!this.state.assignment_id) {
        // new assignment, create
        const assignment_id = await api.createUserAssignment(assignment)
        // this.setState({assignment_id: assignment_id})
        await this.setStateAsync(prevState => ({
          ...prevState,
          assignment_id: assignment_id
        }));
      }
      else {
        // existing assignment, update
        // console.log('saving:upate')
        await api.updateUserAssignment(this.state.assignment_id, assignment)
      }
    }
    else {
      console.log('not logged in. skip saving.')
    }
    // update essay saved status
    this.essayRef.current?.essaySaved()
    // show feedback and hide spinner
    await this.setStateAsync(prevState => ({
      ...prevState,
      assignment: assignment,
      scoring: false,
    }))
    // change key so entire view can be refreshed when feedback is updated
    // this is because contentEditable contents within feedback view cannot be updated via props or states
    this.forceUpdateFeedback()
  }

  // displayResult(result) {
    // TODO: move to score result
    // let grade = this.props.grade;
    // let grammar_complex = null;
    // if (grade == "EK-G15-OPN") {
    //   grammar_complex = result.grammar_vocabulary.grammar_complexity_score >= 4;
    // } else if (['EK-G30-OPN', 'EK-G25-OPN', 'EK-G20-OPN', 'EK-G30-EML'].includes(grade)) {
    //   grammar_complex = result.grammar_vocabulary.grammar_complexity_score >= 1;
    // }
    //
    // let vocabulary_complex = null;
    // if (grade == "EK-G15-OPN") {
    //   vocabulary_complex = result.grammar_vocabulary.vocabulary_complexity_score >= 0.37;
    // } else if (['EK-G30-OPN', 'EK-G25-OPN', 'EK-G20-OPN', 'EK-G30-EML'].includes(grade)) {
    //   vocabulary_complex = result.grammar_vocabulary.vocabulary_complexity_score >= 0.15;
    // }

  // }

  handleFeedbackModeChange = async(mode) => {
    if (isMobile()) {
      this.setState({...this.state,
      mode: mode})
    }
  }

  onEditorFocused = (focused) => {
    // console.log('onEditorFocused')
    this.setState({...this.state,
    editorFocused: focused});
  }

  scoringButtonStack() {
    return (
      <div className="d-flex align-items-center justify-content-between mb-3">
        {
          this.state.isSample && this.state.assignment.feedback == null &&
          <MDBBtn
            size="lg"
            className="green-button outline lh-sm strong"
            onClick={this.handleShowSample}
          >
            <span style={{display: 'inline-block'}}>サンプル解答で</span>
            <span style={{display: 'inline-block'}}>AI採点</span>
          </MDBBtn>
        }
        {
          (!this.state.isSample || (this.state.isSample && this.state.assignment.feedback != null)) &&
          <MDBBtn
            size="lg"
            className="green-button lh-sm strong"
            onClick={this.handleNewAssignment}
          >
            新しい問題を学習する
          </MDBBtn>
        }
        {
          this.state.assignment.feedback != null ?
            <OverlayTrigger placement="top" overlay={
              <Tooltip>書いた文章をAI評価する</Tooltip>
            }>
              <MDBBtn
                size="lg"
                className="orange-button lh-sm strong ms-auto px-5"
                onClick={this.handleScore}
              >
                <span style={{ display: 'inline-block' }}>AI</span>
                <span style={{ display: 'inline-block' }}>採点</span>
                {
                  this.state.scoring &&
                  <MDBSpinner role='status' className="ms-1" style={{ width: '1rem', height: '1rem' }} />
                }
              </MDBBtn>
            </OverlayTrigger>
            :
            this.state.isSample ?
              <OverlayTrigger placement="top" overlay={
                <Tooltip>書いた文章をAI評価する</Tooltip>
              }>
                <MDBBtn
                  size="lg"
                  className="orange-button lh-sm strong ms-auto px-5"
                  onClick={this.handleScore}
                >
                  <span style={{ display: 'inline-block' }}>AI</span>
                  <span style={{ display: 'inline-block' }}>採点</span>
                  {
                    this.state.scoring &&
                    <MDBSpinner role='status' className="ms-1" style={{ width: '1rem', height: '1rem' }} />
                  }
                </MDBBtn>
              </OverlayTrigger>
              :
              <OverlayTrigger placement="top" overlay={
                <Tooltip>書いた文章をAI評価（保存）する</Tooltip>
              }>
                <MDBBtn size="lg" className="orange-button lh-sm strong ms-auto px-5" onClick={this.handleScore}>
                  <span style={{ display: 'inline-block' }}>AI</span>
                  <span style={{ display: 'inline-block' }}>採点</span>
                  {
                    this.state.scoring &&
                    <MDBSpinner role='status' className="ms-1" style={{ width: '1rem', height: '1rem' }} />
                  }
                </MDBBtn>
              </OverlayTrigger>
        }
      </div>
    )
  }

  assignmentEssay() {
    var layoutAnswerUnderQuestion = this.state.assignment.feedback != null
    let pxForCol = '';
    if (this.isMobile()){
      layoutAnswerUnderQuestion = true;
      pxForCol = 'px-0 card-padding-mobile';
    }

    let assignmentFull = false;
    let supplementCol = null;
    if (this.state.assignment.question.type === 'opinion') {
      assignmentFull = false;
    }
    else if (['email', 'summary'].includes(this.state.assignment.question.type)) {
      assignmentFull = true;
      supplementCol = <Col
        xs={layoutAnswerUnderQuestion ? 12 : 6}
        className={`mt-3 ${pxForCol} ${!this.isMobile() || this.state.activeTab === "question" ? "visible" : "collapse"}`}
      >
        <AssignmentSupplementCard assignment={this.state.assignment} />
      </Col>
    }
    else {
      throw new Error(`Unknown assignment type: ${this.props.assignment.question.type}`);
    }


    return (
      <Col>
        {this.isMobile() &&
          <div className="sticky-top px-3 tab-header mb-3">
            <MDBTabs fill justify id='questionAnswerTab'>
              <MDBTabsItem>
                <MDBTabsLink className="left" active={this.state.activeTab === "question"} onClick={() => { this.setState(prevState => ({ ...prevState, activeTab: "question" })) }}>
                  問題</MDBTabsLink>
              </MDBTabsItem>
              <MDBTabsItem>
                <MDBTabsLink className="right" active={this.state.activeTab === "answer"} onClick={() => { this.setState(prevState => ({ ...prevState, activeTab: "answer" })) }}>
                  あなたの解答</MDBTabsLink>
              </MDBTabsItem>
            </MDBTabs>
          </div>
        }
        <Row>
          <Col
            xs={layoutAnswerUnderQuestion ? 12 : (assignmentFull ? 12: 6)}
            className={`${pxForCol} ${!this.isMobile() || this.state.activeTab === "question" ? "visible" : "collapse"}`}
          >
            <AssignmentCard assignment={this.state.assignment} />
          </Col>
          {supplementCol}
          {this.isMobile() &&
            <Col xs={12} className={`my-3 text-center ${!this.isMobile() || this.state.activeTab === "question" ? "visible" : "collapse"}`}>
              <MDBBtn
                size="lg"
                className="green-button px-5"
                onClick={() => {
                  this.setState(prevState => ({
                    ...prevState,
                    activeTab: "answer"
                  }));
                }}
              >
                解答しに行く
              </MDBBtn>
            </Col>
          }
          <Col
            xs={layoutAnswerUnderQuestion ? 12 : 6}
            className={`${layoutAnswerUnderQuestion || assignmentFull ? 'mt-3':''} ${pxForCol} ${!this.isMobile() || this.state.activeTab === "answer" ? "visible" : "collapse"}`}
          >
            <Row className='mx-0'>
              <Col className={`${pxForCol} px-md-0`}>
                <EssayView
                  key={this.state.essaySeed}
                  ref={this.essayRef}
                  assignment={this.state.assignment}
                  keyboardOff={this.state.mode == 'feedback'}
                  onEditorFocused={this.onEditorFocused}
                />
              </Col>
            </Row>
            <Row className="mt-3 mx-0">
              <Col className='px-md-4'>
                {this.scoringButtonStack()}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    )
  }

  render() {
    if (this.state.loading) {
      return (
        <Alert className="p-3 p-md-5 m-3 m-md-5" variant="secondary">
          <Container>
            <Row className="text-center text-muted">
              <Col>
                {this.state.loadingMessage}
              </Col>
            </Row>
            <Row className="text-center mt-3">
              <Col>
                <MDBSpinner style={{ width: '3rem', height: '3rem' }} color='secondary' />
              </Col>
            </Row>
          </Container>
        </Alert>
      )
    }
    if (!this.state.assignment) {
      return null
    }

    return (
      <Container>
        <div className="mt-3 d-flex align-items-center justify-content-between">
          <MDBBreadcrumb className="pt-3">
            <MDBBreadcrumbItem>
              <MDBBtn color='secondary' outline rounded onClick={this.handleHome}>
                ホーム
              </MDBBtn>
            </MDBBreadcrumbItem>
            <MDBBreadcrumbItem active className="mt-1">
              <span className="fw-bold">
                {this.state.assignment && this.state.assignment.title}
                {this.state.isSample && ('（サンプル）')}
              </span>
            </MDBBreadcrumbItem>
          </MDBBreadcrumb>
        </div>
        <Row className="py-3 assignment-title-pane">
          <Stack direction='horizontal' gap={1}>
            {
              this.state.assignment &&
              <div>
                <EikenLogo grade={this.state.assignment.question.grade} style={{ width: '150' }} /><span className='fw-bold ms-5 h3'>{this.getTypeText(this.state.assignment.question.type)}</span>
              </div>
            }
          </Stack>
        </Row>

        <Row>
          {this.assignmentEssay()}
          {this.state.assignment.feedback &&
            <Col className="d-none d-md-block">
              <VerticalFeedback
                key={this.state.feedbackSeed}
                feedback={this.state.assignment.feedback}
                question_data={this.state.assignment.question}
              />
            </Col>
          }
        </Row>
        {
          this.state.assignment.feedback &&
          <Row className={`d-block d-md-none ${this.state.activeTab === "answer"? "visible":"invisible"}`}>
            <HorizontalFeedback
              key={this.state.feedbackSeed}
              feedback={this.state.assignment.feedback}
              onModeChange={this.handleFeedbackModeChange}
              keyboardFocused={this.state.editorFocused}
              question_data={this.state.assignment.question}
              sizeUpdated={this.handleHorizontalFeedbackSizeUpdated}
            />
          </Row>
        }
        <Dialog ref={(el) => { this.dialog = el }} />
        <div style={{ height: this.state.spacerHeight }}/>
        <ChatDialog ref={this.chatDialogRef} show={this.state.chatDialogShow} onHide={this.hideChat}/>
      </Container>
    );
  }
}

class App extends React.Component {
  render() {
    return (
      <div style={{ backgroundColor: 'var(--light-green)'}}>
        <Helmet>
          <title>UGUIS.AI - 英語ライティング練習サービス - 学習{this.props.isSample ? '（サンプル）' : ''}</title>
        </Helmet>
        <AssignmentContent
          grade={this.props.grade}
          type={this.props.type}
          assignment_id={this.props.assignment_id}
          topic={this.props.topic}
          isSample={this.props.isSample}
          navigate={this.props.navigate}
        />
      </div>
    );
  }
}

export default withRouter(App);
